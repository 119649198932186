/* font family link */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');

@font-face {
    font-family: 'Proxima Nova';
    font-weight: bold;
    font-style: normal;
    src: local("Proxima Nova"), url('../font/Proxima-Nova-Alt-Bold.otf') format('opentype'), url('../font/Proxima-Nova-ScOsf-Bold.otf') format('opentype'), url('../font/Proxima-Nova-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-style: normal;
    src: local("Proxima Nova"), url('../font/Proxima-Nova-Alt-Regular.otf') format('opentype'), url('../font/ProximaNova-Regular.otf') format('opentype');
}

/* comman css start */

html,
body {
    overflow-x: hidden;
}

body {
    font-family: 'Raleway', sans-serif;
}

.container {
    max-width: 1243px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* comman css end */

/* header css start */

.zl_header_section .container {
    max-width: 1344px;
}

.zl_header_social_btn .zl_header_social_link:first-child {
    margin-left: 0;
}

/* header css end */

/* footer css start */

.zl_footer_section .container {
    max-width: 1344px;
}

.zl_featured_slider_content .zl_featured_slider_heading,
.zl_v3_footer_section .zl_footer_copyright_menu_content {
    font-family: 'Cabin', sans-serif;
}

.zl_featured_slider_content .tns-outer .tns-controls button {
    top: 50%;
}

/* footer css end */

/* left sidebar css start */

.zl_left_sidebar_content .zl_left_sidebar_nav .zl_left_sidebar_items:last-child {
    margin-bottom: 0;
}

.zl_left_sidebar_content .zl_left_sidebar_second_nav::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: calc(100% - 20px);
    height: 1px;
    background: #d4d4d4;
}

.zl_left_sidebar_content .zl_bottom_content .zl_bottom_label_content > div:last-child {
    margin-bottom: 0;
}

.zl_left_sidebar_content .zl_mobile_left_sidebar_content .zl_mobile_left_sidebar_nav_links::before {
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    border-left: 6.5px solid transparent;
    border-right: 6.5px solid transparent;
    border-top: 8px solid #c1c1c1;
    pointer-events: none;
}

.zl_left_sidebar_content .zl_mobile_left_sidebar_content .zl_mobile_left_sidebar_nav_links select {
    outline: 0;
}

/* left sidebar css end */

/* card css start */

.zl_card .zl_card_body h2 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

/* card css end */

/* home page css end */

/* dashboard css start */

.zl_page_main_sub_heading {
    font-family: 'Proxima Nova';
}

/* dashboard css end */

/* galleries css start */

.zl_galleries_image_content .zl_galleries_image_items .zl_galleries_image_items_img_hover .zl_galleries_image_items_hover {
    transition: all 0.5s ease-in-out;
}

.zl_galleries_image_content .zl_galleries_image_items .zl_galleries_image_items_img_hover:hover .zl_galleries_image_items_hover {
    transform: scale(1);
    opacity: 1;
}

/* galleries css end */

/* subscriptions css start */

.zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status label::after,
.zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status label::before {
    transition: all 0.5s ease-in-out;
}

/* subscriptions css end */

/* read more text model css start */

.zl_read_more_model {
    transform: translate(-50%,-50%);
}

    .zl_read_more_model .zl_card_body .zl_card_heading {
        -webkit-line-clamp: unset;
    }

/* read more text model css end */

/* lightbox gallery css start */

.lg-outer {
    transform: translateX(-50%);
}

/* lightbox gallery css end */

/* home page css start */


.tns-outer .tns-controls button {
    font: normal normal normal 20px/1 Raleway,sans-serif;
    position: relative;
    display: block;
    font-size: 4rem;
    font-weight: 200;
    position: absolute;
    top: 26%;
    transform: translateY(-50%);
    z-index: 10;
    padding: 1rem;
    color: #fff;
    background: transparent;
}

    .tns-outer .tns-controls button:hover {
        background-color: hsla(0,0%,4%,.5);
    }

    .tns-outer .tns-controls button[data-controls="prev"] {
        left: 0;
    }

    .tns-outer .tns-controls button[data-controls="next"] {
        right: 0;
    }

/* home page css end */

/* checkout css start */

.zl_checkout_content .zl_checkout_column .zl_checkout_detail_inputs .zl_checkout_detail_select_row select {
    background-image: none;
    appearance: none;
}

.zl_checkout_content .zl_checkout_column .zl_checkout_detail_inputs .zl_checkout_detail_select_row::before {
    content: "";
    position: absolute;
    right: 24px;
    top: 61%;
    transform: translateY(-50%);
    border-top: 8px solid #c1c1c1;
    border-bottom: 8px solid #c1c1c100;
    border-right: 8px solid #c1c1c100;
    border-left: 8px solid #c1c1c100;
    pointer-events: none;
}

/* checkout css end */

/* details page css start */
.zl_detaile_product_content .zl_detaile_product_right_text h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.zl_detaile_product_content .zl_detaile_product_right_text .zl_detail_product_count_total_price .zl_detail_product_count_text {
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    padding: 0 14px;
}
/* details page css end */

/* experience page css start */

.zl_participant_offer_row .zl_card_body .zl_card_heading {
    display: block;
    min-height: auto;
    height: auto;
}

/* experience page css end */

.zl_full_video .vjs-big-play-button,
.zl_full_video .vjs-big-play-button:focus,
.zl_full_video .vjs-big-play-button:hover,
.zl_full_video:hover .vjs-big-play-button {
    width: 68px;
    height: 72px;
    opacity: 0.9;
    border-radius: 16px;
    background-color: #c9a42c;
    border: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

    .zl_full_video .vjs-big-play-button .vjs-icon-placeholder::after {
        content: "Play";
        font-size: 15px;
        font-weight: 200;
        display: block;
        font-family: "Raleway", sans-serif;
        line-height: 1.5;
    }

    .zl_full_video .vjs-big-play-button .vjs-icon-placeholder::before {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

.vjs-playing .vjs-big-play-button,
.zl_full_video.vjs-playing:hover .vjs-big-play-button {
    display: none;
}


/* media query start */

@media(max-width: 1440px) {
    /* artistshare profile page css start */
    .zl_artistshare_profile_left_content {
        max-width: 55%;
    }

    .zl_artistshare_profile_right_content {
        max-width: 45%;
    }
    /* artistshare profile page css end */
}

@media(max-width: 1400px) {
    /* artistshare profile page css start */
    .zl_artistshare_profile_heading_paragraph .zl_artistshare_profile_heading {
        font-size: 84px;
    }
    /* artistshare profile page css end */

    /* artist project page css start */
    .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_left_heading,
    .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_right_heading {
        font-size: 63px;
    }

        .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_left_heading span {
            font-size: 33px;
        }
    /* artist project page css end */
}

@media(max-width: 1300px) {
    /* artistshare profile page css start */
    .zl_artistshare_profile_heading_paragraph .zl_artistshare_profile_heading {
        font-size: 77px;
    }
    /* artistshare profile page css end */
}

@media(max-width: 1250px) {
    /* header css start */
    .zl_header_content .zl_header_nav .zl_header_items {
        padding: 0 9px;
    }
    /* header css end */

    /* home page css start */

    /* card css start */
    .zl_card .zl_card_body .zl_card_buttons a {
        font-size: 11px;
        padding: 14px 5px;
    }
    /* card css end */

    /* home page css end */

    /* subscriptions css start */

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content {
        max-width: 692px;
    }

    .zl_artist_subscriptions .zl_artist_subscriptions_btns {
        max-width: 215px;
    }

    /* subscriptions css end */
}

@media(max-width: 1200px) {
    /* header css start */
    .zl_header_content .zl_header_right_content .zl_header_right_nav {
        margin-left: 20px;
    }
    /* header css end */

    /* galleries player css start */

    .zl_image_gallery_section .zl_image_gallery_items .zl_image_gallery_text_msg p {
        font-size: 14px;
    }

    /* galleries player css end */

    /* subscriptions css start */

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading_text h4:first-child,
    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_heading {
        max-width: 33%;
    }

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading_text h4,
    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status {
        max-width: 66%;
    }

    /* subscriptions css start */

    /* artistshare profile page css start */
    .zl_artistshare_profile_heading_paragraph .zl_artistshare_profile_heading {
        font-size: 70px;
    }
    /* artistshare profile page css end */

    /* help & support page css start */
    .zl_help_support_heading_search .zl_help_support_heading {
        font-size: 65px;
    }

    .zl_help_support_data_content .zl_help_support_data .zl_help_support_data_main_heading {
        font-size: 39px;
        line-height: 1.3;
    }

    .zl_help_support_data_content .zl_help_support_data .zl_help_support_data_list h3 {
        font-size: 22px;
    }
    /* help & support page css end */
    /* artist project page css start */
    .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_left_heading,
    .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_right_heading {
        font-size: 49px;
    }

        .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_left_heading span {
            font-size: 25px;
        }
    /* artist project page css end */

    /* help&support page css start */

    .zl_help_support_type_help_content .zl_help_support_type_help .zl_help_support_table_heading {
        font-size: 18px;
    }

    /* help&support page css end */
}

@media(max-width: 1100px) {
    /* comman css start */
    .zl_mainbody_right_content {
        padding-left: 40px;
    }
    /* comman css end */

    /* header css start */
    .zl_header_content .zl_header_logo {
        max-width: 185px;
    }

    .zl_header_content .zl_header_nav {
        padding-left: 20px;
    }
    /* header css end */

    /* home page css start */

    /* card css start */
    .zl_card .zl_card_body .zl_card_buttons a {
        font-size: 10px;
    }
    /* card css end */

    /* home page css end */

    /* galleries player css start */

    .zl_image_gallery_section .zl_image_gallery_items .zl_image_gallery_text_msg,
    .zl_image_gallery_section .zl_col_12 .zl_image_gallery_items .zl_image_gallery_text_msg {
        padding: 10px 15px;
    }

    /* galleries player css end */

    /* subscriptions css start */

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content {
        max-width: 692px;
    }

    .zl_artist_subscriptions .zl_artist_subscriptions_btns {
        max-width: 215px;
    }

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading_text h4:first-child,
    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_heading {
        max-width: 36%;
    }

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading_text h4,
    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status {
        max-width: 64%;
    }

        .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status label {
            padding: 0 13px;
        }

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status {
        margin: 0 -13px;
    }

    /* subscriptions css end */

    /* artistshare profile page css start */
    .zl_artistshare_profile_heading_paragraph .zl_artistshare_profile_heading {
        font-size: 65px;
    }
    /* artistshare profile page css end */

    /* help&support page css start */

    .zl_help_support_type_help_content .zl_help_support_type_help .zl_help_support_table_heading,
    .zl_help_support_type_help_content .zl_help_support_type_help li a {
        font-size: 16px;
    }

    /* help&support page css end */
}

@media(max-width: 1023px) {
    /* comman css start */
    .zl_mainbody_right_content {
        padding-left: 0;
    }
    /* comman css end */

    /* header css start */
    .zl_header_content .zl_header_nav {
        padding-left: 30px;
    }

        .zl_header_content .zl_header_nav .zl_header_items {
            padding: 7px 0;
        }

    .zl_header_content .zl_toggel_checkbox:checked ~ .zl_header_nav {
        display: block;
    }
    /* header css end */

    /* home page css start */

    /* card css start */
    .zl_card .zl_card_body .zl_card_buttons a {
        font-size: 12px;
        padding: 14px 15px;
    }
    /* card css end */

    /* home page css end */

    /* galleries player css start */

    .zl_image_gallery_section .zl_image_gallery_items .zl_image_gallery_text_msg,
    .zl_image_gallery_section .zl_col_12 .zl_image_gallery_items .zl_image_gallery_text_msg {
        left: 15px;
        bottom: 15px;
    }

    /* galleries player css end */

    /* subscriptions css start */

    .zl_artist_subscriptions .zl_artist_subscriptions_btns,
    .zl_artist_subscriptions .zl_subscriptions_artist_status_content {
        max-width: 100%;
    }

        .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading_text h4:first-child,
        .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_heading {
            max-width: 40%;
        }

        .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading_text h4,
        .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status {
            max-width: 60%;
        }

    /* subscriptions css end */

    /* help&support page css start */

    .zl_help_support_type_help_content .zl_help_support_type_help {
        max-width: 50%;
    }

        .zl_help_support_type_help_content .zl_help_support_type_help:nth-child(2n) {
            border-right: 0;
        }

    /* help&support page css end */

    /* artistshare profile page css start */
    .zl_artistshare_profile_left_content,
    .zl_artistshare_profile_right_content {
        max-width: 100%;
    }
    /* artistshare profile page css end */

    .zl_news_main_page_body .zl_artistshare_profile_more_artist_list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        display: grid;
        column-gap: 2.25rem;
    }
}

@media(max-width: 991px) {
    /* help & support page css start */
    .zl_help_support_heading_search .zl_help_support_heading {
        font-size: 55px;
    }
    /* help & support page css end */
}

@media(max-width: 767px) {
    /* galleries css start */

    .zl_galleries_image_content .zl_galleries_image_items .zl_galleries_image_items_img_hover {
        border-radius: 4px;
    }

    /* galleries css end */

    /* galleries player css start */

    .zl_image_gallery_section .zl_image_gallery_items .zl_image_gallery_text_msg,
    .zl_image_gallery_section .zl_col_12 .zl_image_gallery_items .zl_image_gallery_text_msg {
        width: 58%;
    }

        .zl_image_gallery_section .zl_image_gallery_items .zl_image_gallery_text_msg p {
            font-size: 13px;
        }

    /* galleries player css end */

    /* subscriptions css start */

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading_text h4:first-child,
    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_heading {
        max-width: 150px;
        min-width: 150px;
    }

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading_text h4,
    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status {
        max-width: 100%;
    }

    /* subscriptions css end */

    /* lightbox gallery css start */

    .lg-inner .lg-item .lg-img-wrap img {
        /* height: 100vh !important; */
    }

    /* lightbox gallery css end */

    /* footer css start */
    .tns-outer .tns-controls button {
        padding: 16px 5px;
        font-size: 45px;
    }
    /* footer css end */

    /* help&support page css start */

    .zl_help_support_type_help_content .zl_help_support_type_help {
        max-width: 100%;
        border-right: 0;
    }

    /* help&support page css end */
}

@media(max-width: 640px) {
    /* artistshare profile page css start */
    .zl_artistshare_profile_heading_paragraph .zl_artistshare_profile_heading {
        font-size: 50px;
    }
    /* artistshare profile page css end */
    /* help & support page css start */
    .zl_help_support_data_content .zl_help_support_data .zl_help_support_data_main_heading {
        font-size: 32px;
    }

    .zl_help_support_data_content .zl_help_support_data .zl_help_support_data_list h3 {
        font-size: 21px;
    }

    .zl_help_support_data_content .zl_help_support_data .zl_help_support_data_list .zl_help_support_data_paragraph p,
    .zl_help_support_data_privacy_policy,
    .zl_help_support_data_content .zl_help_support_data .zl_help_support_data_list .zl_help_support_data_list_nav ul li {
        font-size: 16px;
    }

    .zl_help_support_heading_search .zl_help_support_heading {
        font-size: 35px;
    }
    /* help & support page css end */

    /* artist project page css start */
    .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_left_heading,
    .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_right_heading {
        font-size: 42px;
    }

    .zl_artist_project_content .zl_artist_project_list_row .zl_artist_project_right_list h3 {
        font-size: 21px;
    }

    .zl_artist_project_content .zl_artist_project_list_row .zl_artist_project_right_list .zl_artist_project_right_list_paragraph li {
        font-size: 15px;
    }

    .zl_artist_project_content .zl_artist_project_list_row .zl_artist_project_left_list .zl_artist_project_list_name_video h3 {
        font-size: 25px;
    }
    /* artist project page css end */
}

@media (max-width: 639px) {
    .zl_news_main_page_body .zl_artistshare_profile_more_artist_list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media(max-width: 400px) {
    /* header css start */
    .zl_header_content .zl_header_logo {
        max-width: 189px;
    }
    /* header css end */

    /* home page css start */

    /* card css start */
    .zl_card .zl_card_body .zl_card_buttons a {
        padding: 14px 13px;
    }
    /* card css end */

    /* home page css end */

    /* artistshare profile page css start */
    .zl_artistshare_profile_heading_paragraph .zl_artistshare_profile_heading {
        font-size: 38px;
    }

    .zl_artistshare_profile_photo_name .zl_artistshare_profile_user_name {
        font-size: 32px;
    }
    /* artistshare profile page css end */
    /* help & support page css start */
    .zl_help_support_data_content .zl_help_support_data .zl_help_support_data_main_heading {
        font-size: 24px;
        margin-bottom: 16px;
    }
    /* help & support page css end */

    /* artist project page css start */
    .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_right_heading {
        font-size: 34px;
    }

    .zl_artist_project_page_body .container {
        padding: 0 15px;
    }

    .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_left_heading {
        font-size: 34px;
    }

        .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_left_heading span {
            font-size: 19px;
        }

    .zl_detail_archived_completed_date span {
        padding: 3px 10px 4px;
    }
    /* artist project page css end */
}

@media(max-width: 374px) {
    /* header css start */

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading_text h4:first-child,
    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_heading {
        max-width: 135px;
        min-width: 135px;
    }

        .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_heading h3 {
            font-size: 14px;
        }

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status label::before {
        right: -48px;
    }

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status label::after {
        right: -14px;
    }

    .zl_artist_subscriptions .zl_subscriptions_artist_status_content .zl_subscriptions_artist_status_heading .zl_subscriptions_artist_status input:checked ~ label::after {
        right: -44px;
    }

    /* home page css end */

    /* redeem-sharecard page css start */

    .zl_redeem_form_section .zl_redeem_form_step_two .zl_redeem_form_bottom_btn_content .zl_redeem_form_login_btn {
        max-width: fit-content;
    }

    /* redeem-sharecard page css end */

    /* footer page css start */
    .zl_footer_heading {
        font-size: 20px;
    }
    /* footer page css end */

    /* artist project page css start */
    .zl_artist_project_content .zl_artist_project_heading_row .zl_artist_project_right_heading {
        font-size: 30px;
    }
    /* artist project page css end */

    /* details page css start */
    .zl_detaile_product_content .zl_detaile_product_right_text .zl_detail_product_price_support_row .zl_detail_product_price {
        min-width: auto;
        max-width: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
    }

    .zl_detaile_product_content .zl_detaile_product_right_text .zl_detail_product_price_support_row .zl_detail_product_support_btn {
        padding-left: 12px;
        padding-right: 12px;
        min-width: auto;
    }

    .zl_detaile_product_content .zl_detaile_product_right_text h3 {
        font-size: 22px;
    }

    .zl_detail_archived_completed_date span {
        padding: 3px 6px 4px;
        font-size: 8px;
    }

    .zl_detail_archived_completed_date p {
        font-size: 8px;
    }
    /* details page css end */
}

@media(max-width: 374px) {
    /* details page css start */
    .zl_detaile_product_content .zl_detaile_product_right_text .zl_detail_product_price_support_row .zl_detail_product_price {
        padding: 8px 5px 9px;
        max-width: 60px;
    }

    .zl_detaile_product_content .zl_detaile_product_right_text .zl_detail_product_price_support_row .zl_detail_product_support_btn {
        padding-left: 7px;
        padding-right: 7px;
        font-size: 10px;
    }

        .zl_detaile_product_content .zl_detaile_product_right_text .zl_detail_product_price_support_row .zl_detail_product_support_btn span {
            padding-left: 5px;
            padding-right: 5px;
        }
    /* details page css end */
}

/* media query end */
